const ListActivities = () => import('../views/ListActivities.vue')
const NewActivity = () => import('../views/NewActivity.vue')

export default [
  {
    path: '/employeer/activities',
    component: ListActivities,
    // meta: { requiresAuth: true },
    name: 'activity'
  },
  {
    path: '/employeer/activity/new-activity',
    component: NewActivity,
    // meta: { requiresAuth: true },
    name: 'activity.profile',
    props: true
  }
]
export const auth = {
  namespaced: true,
  state: {
    user: null,
    search:undefined
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setSearch (state, search) {
      state.search = search
    },
    updateEmployee (state, employee) {
      state.user.employee = employee
    },
    updateEntity (state, entity) {
      state.user.entity = entity
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    setSearch ({ commit }, search) {
      commit('setSearch', search)
    },
    updateEmployee ({ commit }, employee) {
      commit('updateEmployee', employee)
    },
    updateEntity ({ commit }, entity) {
      commit('updateEntity', entity)
    }
  },
  getters: {
    getCurrentUser: (state) => {
      return state.user
    },
    getCurrentSearch: (state) => {
      return state.search
    },
    getEmployee: (state) => {
      return state.employee
    }
  },
}
const reportView = () => import("./../views/ReportView.vue");
const GeneratePdfTest = () => import("./../views/GeneratePdfTest.vue");
const TrainingRaport = () => import("./../views/TrainingReport.vue");
const EmployeeReport = () => import("./../views/EmployeeReport.vue");

export default [
  {
    path: "reports",
    component: reportView,
  },
  {
    path: "reports/trainings/:id",
    component: TrainingRaport,
    props: true,
  },
  {
    path: "reports/employees/:id",
    component: EmployeeReport,
    props: true,
  },
  {
    path: "testPdf",
    component: GeneratePdfTest,
  },
];

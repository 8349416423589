<template>
  <v-container class="mt-3">
    <v-row>
      <v-col cols="12" md="8">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="py-6 px-4" min-height="390">
            <v-card-text>
              <v-row class="d-flex flex-column mb-2">
                <div class="d-flex justify-space-between align-center">
                  <div class="font-weight-medium text-caption text-uppercase">
                    {{ $t("add_remove_update") }}
                  </div>
                </div>
                <v-divider class="SimpleSolid"></v-divider>
              </v-row>

              <v-row class="d-flex flex-column">
                <div class="d-flex justify-space-between align-center mb-4">
                  <div class="black--text text-caption text-uppercase">
                    {{ $t("name") }}
                  </div>
                  <div style="width: 80%">
                    <whirelab-input
                      v-model="role.name"
                      :placeholder="$t('job_title')"
                      class="black--text text-caption"
                      required
                    />
                  </div>
                </div>

                <div class="d-flex justify-space-between align-center mb-4">
                  <div class="black--text text-caption text-uppercase">
                    {{ $t("permissions") }}
                  </div>
                  <div style="width: 80%">
                    <treeselect
                      v-model="role.permissions"
                      :multiple="true"
                      :options="permissionsVal"
                      :placeholder="$t('permissions')"
                      value-consists-of="LEAF_PRIORITY"
                      required
                      layout="filled"
                      style="border: 1px solid #9e9e9e; border-radius: 5px"
                    />
                  </div>
                </div>

                <div class="d-flex justify-space-between mb-4">
                  <div class="black--text text-caption text-uppercase">
                    {{ $t("description") }}
                  </div>
                  <div style="width: 80%">
                    <whirelab-text-area
                      v-model="role.description"
                      :placeholder="$t('main_responsibilities')"
                      class="black--text text-caption"
                    />
                  </div>
                </div>

                <div class="whirelab-buttons-edit">
                  <v-btn
                    v-if="role.id"
                    color="primary"
                    class="mr-2"
                    outlined
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="cancel()"
                  >
                    <span class="text-caption"> {{ $t("cancel") }}</span>
                  </v-btn>

                  <v-btn
                    v-if="
                      role.id && accessLevel(currentUser, 'update_access_level')
                    "
                    class="mr-2"
                    color="primary"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="updateRoleById"
                  >
                    <span class="text-caption"> {{ $t("update") }}</span>
                  </v-btn>
                  <v-menu offset-y left v-if="role.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        style="background-color: #ccebe9; min-width: 15px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template
                        v-if="accessLevel(currentUser, 'delete_access_level')"
                      >
                        <v-list-item
                          style="min-height: 25px"
                          @click="deleteRoleById()"
                        >
                          <v-list-item-title style="padding-top: 0">
                            <span class="black--text text-caption">
                              {{ $t("delete") }}
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                  <v-btn
                    v-else-if="accessLevel(currentUser, 'add_access_level')"
                    color="primary"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="saveRole"
                  >
                    <span class="text-caption"> {{ $t("save") }}</span>
                  </v-btn>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>

      <v-col cols="12" md="4" class="whirelab-positions-list">
        <template v-for="role in roles">
          <expansion-card-role
            class="mb-2"
            :role="role"
            :key="role.id"
            @clicked="selectedRole"
          />
        </template>
      </v-col>
    </v-row>

    <!-- <v-btn @click="seedPermissions">
      Sinc
    </v-btn> -->
  </v-container>
</template>

<script>
import GET_PERMISSIONS from "../graphql/query/GetPermissions.gql";
import GET_ROLES from "../graphql/query/GetRoles.gql";
import CREATE_ROLE from "../graphql/mutation/CreateRole.gql";
import CREATE_PERMISSION from "../graphql/mutation/CreatePermission.gql";
import UPDATE_ROLE_BY_ID from "../graphql/mutation/UpdateRoleById.gql";
import DELETE_ROLE_BY_ID from "../graphql/mutation/DeleteRoleById.gql";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import WhirelabInput from "@/components/form/WhirelabInput.vue";
import WhirelabTextArea from "@/components/form/WhirelabTextArea.vue";
import ExpansionCardRole from "../components/ExpansionCardRole.vue";
import accessLevel from "@/mixins/access-level";
import { mapGetters } from "vuex";

export default {
  name: "ListRole",
  mixins: [accessLevel],
  components: {
    Treeselect,
    WhirelabInput,
    WhirelabTextArea,
    ExpansionCardRole,
  },
  data: () => ({
    permissions: [],
    roles: [],
    role: {
      name: "",
      permissions: [],
      description: "",
      type: "ENTITY",
    },
    isLoading: false,
    valid: true,
  }),
  apollo: {
    permissions: {
      query: GET_PERMISSIONS,
      variables() {
        return {
          type: "ENTITY",
        };
      },
    },
    roles: {
      query: GET_ROLES,
      variables() {
        return {
          type: "ENTITY",
        };
      },
    },
  },
  computed: {
    ...mapGetters({
      currentSearch: "auth/getCurrentSearch",
      currentUser: "auth/getCurrentUser",
    }),
    permissionsVal() {
      if (this.permissions) {
        return [
          {
            id: this.getPermissionId("dashboard"),
            label: this.$t(this.$t("dashboard")),
          },
          {
            id: this.getPermissionId("setting"),
            label: this.$t(this.$t("settings")),
          },
          {
            id: "positions",
            label: this.$t("positions"),
            children: [
              {
                id: this.getPermissionId("positions"),
                label: `${this.$t("show")} ${this.$t("positions")}`,
              },
              {
                id: this.getPermissionId("list_positions"),
                label: this.$t("list_positions"),
              },
              {
                id: this.getPermissionId("add_position"),
                label: this.$t("add_position"),
              },
              {
                id: this.getPermissionId("update_position"),
                label: this.$t("update_position"),
              },
              {
                id: this.getPermissionId("delete_position"),
                label: this.$t("delete_position"),
              },
            ],
          },
          {
            id: "address",
            label: this.$t("address"),
            children: [
              {
                id: this.getPermissionId("address"),
                label: `${this.$t("show")} ${this.$t("address")}`,
              },
              {
                id: this.getPermissionId("list_address"),
                label: this.$t("list_address"),
              },
              {
                id: this.getPermissionId("add_address"),
                label: this.$t("add_address"),
              },
              {
                id: this.getPermissionId("update_address"),
                label: this.$t("update_address"),
              },
              {
                id: this.getPermissionId("delete_address"),
                label: this.$t("delete_address"),
              },
            ],
          },
          {
            id: "access_level",
            label: this.$t("access_level"),
            children: [
              {
                id: this.getPermissionId("access_level"),
                label: `${this.$t("show")} ${this.$t("access_level")}`,
              },
              {
                id: this.getPermissionId("list_access_level"),
                label: this.$t("list_access_level"),
              },
              {
                id: this.getPermissionId("add_access_level"),
                label: this.$t("add_access_level"),
              },
              {
                id: this.getPermissionId("update_access_level"),
                label: this.$t("update_access_level"),
              },
              {
                id: this.getPermissionId("delete_access_level"),
                label: this.$t("delete_access_level"),
              },
            ],
          },
          {
            id: "users",
            label: this.$t("users"),
            children: [
              {
                id: this.getPermissionId("users"),
                label: `${this.$t("show")} ${this.$t("users")}`,
              },
              {
                id: this.getPermissionId("list_users"),
                label: this.$t("list_users"),
              },
              {
                id: this.getPermissionId("add_user"),
                label: this.$t("add_user"),
              },
              {
                id: this.getPermissionId("update_user"),
                label: this.$t("update_user"),
              },
              {
                id: this.getPermissionId("delete_user"),
                label: this.$t("delete_user"),
              },
            ],
          },
          {
            id: "employees",
            label: this.$t("employees"),
            children: [
              {
                id: this.getPermissionId("employees"),
                label: `${this.$t("show")} ${this.$t("employees")}`,
              },
              {
                id: this.getPermissionId("list_employees"),
                label: this.$t("list_employees"),
              },
              {
                id: this.getPermissionId("add_employee"),
                label: this.$t("add_employee"),
              },
              {
                id: this.getPermissionId("update_employee"),
                label: this.$t("update_employee"),
              },
              {
                id: this.getPermissionId("delete_employee"),
                label: this.$t("delete_employee"),
              },
              {
                id: this.getPermissionId("define_employee_shifts"),
                label: this.$t("define_employee_shifts"),
              },
              {
                id: this.getPermissionId("define_employee_work_calendar"),
                label: this.$t("define_employee_work_calendar"),
              },
            ],
          },
          {
            id: "departments",
            label: this.$t("departments"),
            children: [
              {
                id: this.getPermissionId("departments"),
                label: `${this.$t("show")} ${this.$t("departments")}`,
              },
              {
                id: this.getPermissionId("list_departments"),
                label: this.$t("list_departments"),
              },
              {
                id: this.getPermissionId("add_department"),
                label: this.$t("add_department"),
              },
              {
                id: this.getPermissionId("update_department"),
                label: this.$t("update_department"),
              },
              {
                id: this.getPermissionId("delete_department"),
                label: this.$t("delete_department"),
              },
            ],
          },
          {
            id: "trainings",
            label: this.$t("trainings"),
            children: [
              {
                id: this.getPermissionId("trainings"),
                label: `${this.$t("show")} ${this.$t("trainings")}`,
              },
              {
                id: this.getPermissionId("list_trainings"),
                label: this.$t("list_trainings"),
              },
              {
                id: this.getPermissionId("add_training"),
                label: this.$t("add_training"),
              },
              {
                id: this.getPermissionId("update_training"),
                label: this.$t("update_training"),
              },
              {
                id: this.getPermissionId("delete_training"),
                label: this.$t("delete_training"),
              },
              {
                id: this.getPermissionId("add_training_participants"),
                label: this.$t("add_training_participants"),
              },
              {
                id: this.getPermissionId("add_training_trainers"),
                label: this.$t("add_training_trainers"),
              },
              {
                id: this.getPermissionId("add_training_guests"),
                label: this.$t("add_training_guests"),
              },
              {
                id: this.getPermissionId("confirm_training_attendance"),
                label: this.$t("confirm_training_attendance"),
              },
            ],
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    cancel() {
      delete this.role.id;
      this.role.name = "";
      this.role.permissions = [];
      this.role.description = "";
      this.$refs.form.reset();
    },

    getPermissionId(key) {
      const findedIndex = this.permissions.findIndex((p) => {
        return p.name == key;
      });

      if (findedIndex >= 0) {
        return this.permissions[findedIndex].id;
      } else {
        return "";
      }
    },
    async seedPermissions() {
      const permissions = [
        // {
        //   id: "f042bffc-70f6-48c8-bd1c-3a4191a23630",
        //   name: "setting",
        //   type: "ENTITY",
        // },
        // {
        //   id: "37267ace-a8f5-4751-b36c-e80d278c4a15",
        //   name: "positions",
        //   type: "ENTITY",
        // },
        // {
        //   id: "1265797c-2b44-471b-b1eb-6ce4c703eef5",
        //   name: "list_positions",
        //   type: "ENTITY",
        // },
        // {
        //   id: "dd754b0e-38c2-43cc-bfa2-704c7f5df795",
        //   name: "add_position",
        //   type: "ENTITY",
        // },
        // {
        //   id: "398dcdd3-bceb-4657-a5ed-7e6d22050593",
        //   name: "update_position",
        //   type: "ENTITY",
        // },
        // {
        //   id: "4b1e1eff-ecf7-45f0-83ea-803d8415b09b",
        //   name: "delete_position",
        //   type: "ENTITY",
        // },
        // {
        //   id: "8eb5b291-b11a-4ea0-8936-8616f3d50623",
        //   name: "address",
        //   type: "ENTITY",
        // },
        // {
        //   id: "7f08f95f-fa21-41a8-8336-8bf6b4616234",
        //   name: "list_address",
        //   type: "ENTITY",
        // },
        // {
        //   id: "b462e159-ce0b-4452-8d49-d8ab9111484e",
        //   name: "add_address",
        //   type: "ENTITY",
        // },
        // {
        //   id: "a2060c03-4da9-484d-b5ec-9b7d0e29b44e",
        //   name: "update_address",
        //   type: "ENTITY",
        // },
        // {
        //   id: "f3bf10b4-7ebb-468d-87e0-b9dc3e943774",
        //   name: "delete_address",
        //   type: "ENTITY",
        // },
        // {
        //   id: "9d5fb54e-0cda-4e37-946e-704104008f5f",
        //   name: "access_level",
        //   type: "ENTITY",
        // },
        // {
        //   id: "b6ce84a2-2f62-43a7-bf2c-8799d9776244",
        //   name: "list_access_level",
        //   type: "ENTITY",
        // },
        // {
        //   id: "9fa6a94b-d246-4fe3-aee5-2679f9fc1150",
        //   name: "add_access_level",
        //   type: "ENTITY",
        // },
        // {
        //   id: "c09fabd7-17d0-4fde-b94f-c3e4d3d41608",
        //   name: "update_access_level",
        //   type: "ENTITY",
        // },
        // {
        //   id: "09892db8-5753-4208-a81a-64e3d446ffcd",
        //   name: "delete_access_level",
        //   type: "ENTITY",
        // },
        // {
        //   id: "0f26a82b-f96c-4b90-a108-2e8653c938eb",
        //   name: "roles",
        //   type: "ENTITY",
        // },
        // {
        //   id: "d2910309-ef07-465e-8480-0a2ff7672e25",
        //   name: "list_roles",
        //   type: "ENTITY",
        // },
        // {
        //   id: "30a08edf-b9ae-4fb4-804f-024d154547b9",
        //   name: "add_role",
        //   type: "ENTITY",
        // },
        // {
        //   id: "cc4230e3-62d0-44f0-8830-253bc47d2f9a",
        //   name: "update_role",
        //   type: "ENTITY",
        // },
        // {
        //   id: "341c78fe-5450-4461-8890-12617479cb9e",
        //   name: "delete_role",
        //   type: "ENTITY",
        // },
        // {
        //   id: "b6fa5ad0-e2c6-4fcd-bacf-79d008c7b98c",
        //   name: "users",
        //   type: "ENTITY",
        // },
        // {
        //   id: "4fcad122-fac9-4bb0-b554-fae9849bb971",
        //   name: "list_users",
        //   type: "ENTITY",
        // },
        // {
        //   id: "d43799dd-1bdf-457b-bf23-30839c8aec23",
        //   name: "add_user",
        //   type: "ENTITY",
        // },
        // {
        //   id: "2f594824-fb6b-450f-8ec0-cffe135245c2",
        //   name: "update_user",
        //   type: "ENTITY",
        // },
        // {
        //   id: "57387e76-d73b-4ac7-ba8b-4f91b8a17ff1",
        //   name: "delete_user",
        //   type: "ENTITY",
        // },
        // {
        //   id: "287971ca-af4e-41b7-bed8-a0847c2125da",
        //   name: "dashboard",
        //   type: "ENTITY",
        // },
        // {
        //   id: "765a6144-f077-4412-a72e-e18211c0034f",
        //   name: "internal_section",
        //   type: "ENTITY",
        // },
        // {
        //   id: "ce7dd8a8-cee8-4b12-a59d-d5d698175ace",
        //   name: "employees",
        //   type: "ENTITY",
        // },
        // {
        //   id: "cef6102f-3acd-4449-9f24-1333254f7d98",
        //   name: "list_employees",
        //   type: "ENTITY",
        // },
        // {
        //   id: "8315e998-504d-4952-a277-649b8c3f3150",
        //   name: "add_employee",
        //   type: "ENTITY",
        // },
        // {
        //   id: "e66cb275-d8ae-4cd4-9375-ea987fff77ca",
        //   name: "departments",
        //   type: "ENTITY",
        // },
        // {
        //   id: "42d13e0b-e169-48db-8d22-bc0ec0b482a4",
        //   name: "list_departments",
        //   type: "ENTITY",
        // },
        // {
        //   id: "5b41d820-b2c8-4df7-af19-30523de18368",
        //   name: "add_department",
        //   type: "ENTITY",
        // },
        // {
        //   id: "becb7fe7-5dbc-400f-9dc8-55b66df2855b",
        //   name: "update_department",
        //   type: "ENTITY",
        // },
        // {
        //   id: "c4955c21-90c3-4ee1-b9f3-f63eed6ebb92",
        //   name: "delete_department",
        //   type: "ENTITY",
        // },
        // {
        //   id: "9ec4c471-fb5b-49e4-ac20-3b4d0c52569a",
        //   name: "trainings",
        //   type: "ENTITY",
        // },
        // {
        //   id: "103e3ead-9f6e-46cc-b62a-e49239a8c25e",
        //   name: "list_trainings",
        //   type: "ENTITY",
        // },
        // {
        //   id: "d588d64a-5c41-4b8a-992a-92a07c397058",
        //   name: "add_training",
        //   type: "ENTITY",
        // },
        // {
        //   id: "d17733f2-450b-4d43-82e1-766f45a4d3f6",
        //   name: "update_training",
        //   type: "ENTITY",
        // },
        // {
        //   id: "acfd5812-55f6-456f-9356-e07915c007a3",
        //   name: "delete_training",
        //   type: "ENTITY",
        // },
        // {
        //   id: "6d06f409-e5e8-4cba-b6cb-c28477d69d15",
        //   name: "external_section",
        //   type: "ENTITY",
        // },
        // {
        //   id: "c3533b10-a555-47d6-ab76-d5799d2b84a4",
        //   name: "update_employee",
        //   type: "ENTITY",
        // },
        // {
        //   id: "c593e336-2924-4f7f-8014-d03df4ed1313",
        //   name: "delete_employee",
        //   type: "ENTITY",
        // },
        // {
        //   id: "a8e8481d-c7f9-4ae9-8a0e-bc8390b42a6b",
        //   name: "trainees",
        //   type: "ENTITY",
        // },
        // {
        //   id: "b7ad4865-a88b-4a67-8f53-77aba773d9de",
        //   name: "list_trainees",
        //   type: "ENTITY",
        // },
        // {
        //   id: "e68b3128-5228-4245-94c3-d8735e65b7a5",
        //   name: "add_trainee",
        //   type: "ENTITY",
        // },
        // {
        //   id: "b216f199-d0dc-46b1-b03b-b06886042ce5",
        //   name: "update_trainee",
        //   type: "ENTITY",
        // },
        // {
        //   id: "fa612773-f8ec-4cc6-9a1b-5d5bbaceaf7a",
        //   name: "delete_trainee",
        //   type: "ENTITY",
        // },
        // {
        //   id: "f505dcf7-d0c8-4a44-8b09-f0021b9d7fdd",
        //   name: "clients",
        //   type: "ENTITY",
        // },
        // {
        //   id: "3d2390e1-584e-4135-a8c9-571f828e0b88",
        //   name: "list_clients",
        //   type: "ENTITY",
        // },
        // {
        //   id: "b878187a-d2aa-4f6f-99b7-984c0dd50f0a",
        //   name: "add_client",
        //   type: "ENTITY",
        // },
        // {
        //   id: "c2633bcd-1f2b-4797-a466-113a8dd0bcc4",
        //   name: "update_client",
        //   type: "ENTITY",
        // },
        // {
        //   id: "0b8a8f16-bee0-49d6-924d-05ca7fcdba07",
        //   name: "delete_client",
        //   type: "ENTITY",
        // },
        // {
        //   id: "0c5de9af-9573-49fa-9f1d-6d7becd8c67d",
        //   name: "external_trainings",
        //   type: "ENTITY",
        // },
        // {
        //   id: "764166b9-9810-4677-87fe-eae34e56f7e1",
        //   name: "list_external_trainings",
        //   type: "ENTITY",
        // },
        // {
        //   id: "85fb43a9-5c31-468f-9581-b2d0835214f7",
        //   name: "add_external_training",
        //   type: "ENTITY",
        // },
        // {
        //   id: "374edfaf-4b4c-48a5-a59b-16adce87db74",
        //   name: "update_external_training",
        //   type: "ENTITY",
        // },
        // {
        //   id: "246993f4-76e5-4fc3-ad15-aab580e20f4f",
        //   name: "delete_external_training",
        //   type: "ENTITY",
        // },

        // {
        //   name: "add_training_participants",
        //   description: "Permission to add participants to a training session",
        //   type: "ENTITY",
        // },
        // {
        //   name: "add_training_trainers",
        //   description: "Permission to add trainers to a training session",
        //   type: "ENTITY",
        // },
        // {
        //   name: "add_training_guests",
        //   description: "Permission to add guests to a training session",
        //   type: "ENTITY",
        // },
        // {
        //   name: "define_employee_shifts",
        //   description: "Permission to define shifts for employees",
        //   type: "ENTITY",
        // },
        // {
        //   name: "define_employee_work_calendar",
        //   description: "Permission to define work calendars for employees",
        //   type: "ENTITY",
        // },
        // {
        //   name: "dashboard",
        //   type: "ENTITY",
        //   description: "Permission to access the dashboard",
        // },
        {
          name: "confirm_training_attendance",
          type: "ENTITY",
          description: "Permission to confirm training attendance",
        },
      ];

      try {
        permissions.forEach(async (permission) => {
          await this.$apollo.mutate({
            mutation: CREATE_PERMISSION,
            variables: {
              createPermissionInput: {
                name: permission.name,
                description: permission.description,
                type: permission.type,
              },
            },
          });
        });

        console.log("Permissões criadas com sucesso!");
      } catch (error) {
        console.error("Erro ao criar permissões:", error);
      }
    },
    async saveRole() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          await this.$apollo.mutate({
            mutation: CREATE_ROLE,
            variables: {
              createRoleInput: {
                ...this.role,
                type: "ENTITY",
              },
            },
            update: (store, { data: { createRole } }) => {
              const data = store.readQuery({ query: GET_ROLES });
              data.roles.push(createRole);
              store.writeQuery({ query: GET_ROLES, data });
              this.successfully = true;
              this.role.name = "";
              this.role.permissions = [];
              this.role.description = "";
              this.$refs.form.reset();
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    async updateRoleById() {
      this.isLoading = true;
      delete this.role.__typename;
      const roleId = this.role.id;
      delete this.role.id;
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_ROLE_BY_ID,
          variables: {
            id: roleId,
            updateRoleInput: {
              ...this.role,
            },
          },
          update: (store, { data: { updateRoleById } }) => {
            const data = store.readQuery({ query: GET_ROLES });
            const findedRoleIndex = data.roles.findIndex(
              (findRole) => findRole.id === this.role.id
            );
            data.roles.splice(findedRoleIndex, 1, updateRoleById);
            store.writeQuery({ query: GET_ROLES, data });
            this.successfully = true;
            this.role.name = "";
            this.role.permissions = [];
            this.role.description = "";
            this.$refs.form.reset();
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteRoleById() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ROLE_BY_ID,
          variables: {
            id: this.role.id,
          },
          update: (store) => {
            const data = store.readQuery({ query: GET_ROLES });
            const findedRoleIndex = data.roles.findIndex(
              (findRole) => findRole.id === this.role.id
            );
            data.roles.splice(findedRoleIndex, 1);
            store.writeQuery({ query: GET_ROLES, data });
            this.successfully = true;
            delete this.role.id;
            this.role.name = "";
            this.role.permissions = [];
            this.role.description = "";
            this.$refs.form.reset();
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    selectedRole(roleId) {
      const role = this.roles.find((findRole) => findRole.id === roleId);
      console.log({ role });

      role.permissions = role.permissions.map((permission) =>
        permission.id ? permission.id : permission
      );

      this.role = Object.assign({}, role);
    },
  },
};
</script>

<style scoped>
.whirelab-buttons-edit {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

@media screen and (max-width: 960px) {
  .whirelab-buttons-edit {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
  }
}
</style>

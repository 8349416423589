<template>
  <v-app-bar
    color="white"
    app
    flat
    elevation="1"
    :clipped-left="!propDrawer"
    :class="{ 'menu-colapse': !propDrawer }"
  >
    <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <!-- <input type="text" /> -->
    <v-text-field
      class="custom-text-field"
      append-icon="mdi-magnify"
      :placeholder="$t('general_Search')"
      v-model="search"
      @input="updateQuery"
      single-line
      hide-details
      dense
      outlined
    ></v-text-field>
    <v-spacer></v-spacer>
    <!-- <v-btn icon color="">
      <v-icon outlined>mdi-information</v-icon>
    </v-btn> -->

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text rounded icon color v-on="on">
          <flag :iso="currentFlag" v-bind:squared="false" />
          <!-- <v-icon right color="">mdi-menu-down</v-icon> -->
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(entry, index) in whirelabLanguages"
          :key="`${index}010`"
          @click="changeLocale(entry)"
        >
          <v-list-item-title class="text-left">
            <div class="d-flex justify-start align-center">
              <div class="mr-2">
                <flag :iso="entry.flag" v-bind:squared="false" />
              </div>
              <div class="mt-1 text-subtitle-1">{{ entry.title }}</div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" class="mr-2" color="black">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </template>
    </v-menu>

    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
      offset-y
      width="400px"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" color="black" class="mr-2">
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
      </template>

      <v-card class="mx-auto text-left" v-if="currentUser">
        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="`/avatar.png`" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1">
                {{ currentUser.firstName }}
              </v-list-item-title>
              <v-list-item-subtitle class="">
                {{ currentUser.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <!-- <v-list-item router to="/entity/profile">
            <v-list-item-action>
              <v-icon class="black--text">mdi-account-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-subtitle-1">{{
              $t("profile")
            }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon class="black--text">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-subtitle-1">{{
              $t("logout")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-menu
      v-if="currentUser && accessLevel(currentUser, 'setting')"
      left
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="black" icon v-bind="attrs" v-on="on" small>
          <v-icon outlined>mdi-cog-outline</v-icon>
        </v-btn>
      </template>

      <v-list>
        <template v-for="(setting, i) in settings">
          <v-list-item
            v-if="setting.show"
            route
            :key="i"
            :to="setting.route"
            :exact="setting.exact"
          >
            <v-list-item-action>
              <v-icon color="black">{{ setting.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-body-1">{{
              setting.title
            }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { GC_USER_ID, GC_USER_TYPE, GC_AUTH_TOKEN, LANG } from "@/constants";
import { mapActions, mapGetters } from "vuex";
import GET_ME from "./../modules/auth/graphql/query/GetEmployee.gql";
import UPDATE_USER_BY_ID from "./../modules/entity/modules/user/graphql/mutation/UpdateUserById.gql";
import LOGOUT from "./../modules/entity/modules/user/graphql/mutation/Logout.gql";
// import GET_TRAININGS from "@/graphql/query/GetTrainings.gql";
import accessLevelMixins from "@/mixins/access-level";
import debounce from "lodash/debounce";

export default {
  name: "AppBar",
  mixins: [accessLevelMixins],
  props: ["propDrawer"],
  data: () => ({
    currentFlag: "pt",
    drawer: false,
    filter: {
      positions: undefined,
    },
    search: undefined,
  }),
  apollo: {
    user: {
      query: GET_ME,
      fetchPolicy: "no-cache",
    },
    // trainings: {
    //   query: GET_TRAININGS,
    //   variables() {
    //     return {
    //       filter: this.filter,
    //       page: 1,
    //       pageSize: 10,
    //     };
    //   },
    //   result({ data }) {
    //     data.trainings.trainings.forEach(async (training) => {
    //       if (training.ongoingDate) {
    //         // this.manipulateStatus(training);
    //       }
    //     });
    //     this.setTrainingState(data.trainings.trainings);
    //   },
    //   fetchPolicy: "no-cache",
    // },
  },
  watch: {
    // search: function (val) {
    //   this.setSearch(val);
    // },
    user: function (val) {
      this.setUserState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    whirelabLanguages: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },

    settings: function () {
      return [
        {
          icon: "mdi-domain",
          title: this.$t("positions"),
          route: "/entity/positions/list",
          exact: true,
          show: this.accessLevel(this.currentUser, "positions"),
        },
        {
          icon: "mdi-domain",
          title: this.$t("address"),
          route: "/entity/address/list",
          exact: true,
          show: this.accessLevel(this.currentUser, "address"),
        },
        {
          icon: "mdi-key-variant",
          title: this.$t("access_level"),
          route: "/entity/roles",
          exact: true,
          show: this.accessLevel(this.currentUser, "access_level"),
        },
        {
          icon: "mdi-account-group",
          title: this.$t("user"),
          route: "/users",
          exact: true,
          show: this.accessLevel(this.currentUser, "users"),
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
      setSearch: "auth/setSearch",
      setTrainingState: "training/setTrainings",
    }),

    updateQuery: debounce(function () {
      this.setSearch(this.search);
    }, 1000),

    async changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;
      localStorage.setItem("language", locale.language);
      localStorage.setItem("flag", locale.flag);
      this.updateLang(locale.language);
    },

    changeDrawer() {
      this.drawer = !this.propDrawer;
      this.$emit("changeDrawer", this.drawer);
    },

    async updateLang(lang) {
      if (this.currentUser) {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_BY_ID,
          variables: {
            id: this.currentUser.id,
            updateUserInput: {
              lang: lang,
              type: this.currentUser.type,
            },
          },
        });
      }
    },

    async logout() {
      try {
        await this.$apollo.mutate({
          mutation: LOGOUT,
           variables: {
            id: this.currentUser.id,
          },
        });
        localStorage.removeItem(GC_USER_ID);
        localStorage.removeItem(GC_USER_TYPE);
        localStorage.removeItem(GC_AUTH_TOKEN);
        this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
        this.$router.push("/login");
      } catch (error) {
        if(error.graphQLErrors?.length > 0) {
          if (error.graphQLErrors[0].message === "Unauthorized") {
            this.$router.push("/login");
          }
        }else {
          alert(error);
        }
      }
    },
  },
  mounted() {
    console.log("login");
  },
  created() {
    console.log("login");

    if (localStorage.getItem(GC_USER_ID) === null) {
      this.logout();
    }
    // if (localStorage.getItem("language") != null) {
    //   this.$i18n.locale = localStorage.getItem("language");
    //   this.currentFlag = localStorage.getItem("flag");
    // } else {
    // this.$i18n.locale = "pt";
    // this.currentFlag = "pt";

    const lang_flags = {
      en: "gb",
      pt: "pt",
    };
    const lang = this.currentUser ? this.currentUser.lang : "pt";
    console.log("====================================");
    console.log(this.currentUser);
    console.log(lang, lang_flags[lang]);
    console.log("====================================");
    this.$i18n.locale = lang;
    this.currentFlag = lang_flags[lang];
    localStorage.setItem("language", lang);
    localStorage.setItem(LANG, lang);
    localStorage.setItem("flag", lang_flags[lang]);
    // }
    // this.updateLang(this.$i18n.locale);
  },
};
</script>
<style scoped>
@media (min-width: 1265px) {
  .menu-colapse {
    margin-left: 52px;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./plugins/FlagIcon";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18n from "./plugins/i18n";
import "@/plugins/vue-croppa";
import "@/plugins/v-currency-field";
import "@/plugins/v-mask";
import "@/plugins/tiptap-vuetify";
import "@/plugins/vue-tel-input-vuetify";
import { apolloProvider } from "./plugins/vue-apollo";
import store from "./store";
import JsonExcel from "vue-json-excel";
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apex-chart', VueApexCharts)
//import Chart from "vue-frappe-chart"
Vue.component("downloadExcel", JsonExcel);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(require('vue-moment'));
//Vue.use(Chart)

Vue.config.productionTip = false;
new Vue({
  store,
  vuetify,
  router,
  apolloProvider,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import axios from "axios";
import { GC_AUTH_TOKEN } from "./../constants";

const baseURL =
  process.env.VUE_APP_HTTP  || 
  "https://kenmare-api-training.whirelab.com";

const certificateURL =
  process.env.VUE_APP_HTTP_CERTIFICATE ||
   "https://rh-cert-demo.whirelab.com/pdf/render-url";



const token = localStorage.getItem(GC_AUTH_TOKEN);
const locale = localStorage.getItem("lang");
const api = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    locale: `${locale}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  },
});

const apiCertificate = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
    locale: `${locale}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
});

export { api, baseURL, apiCertificate, certificateURL };

const Login = () => import("./../views/Login.vue");
const ResetPassword = () => import("./../views/ResetPassword.vue");
const RecoverAccount = () => import("./../views/RecoverAccount.vue");

export default [
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  {
    path: "/recover/account",
    component: RecoverAccount,
    name: "recover-account",
  },
  {
    path: "/reset-password/:id",
    component: ResetPassword,
    name: "reset-password",
    props: true,
  },
];

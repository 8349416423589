const ListTrainings = () => import("../views/ListTrainings.vue");
const NewTraining = () => import("../views/AddOrEditTraining.vue");
const EditTraining = () => import("../views/EditTraining.vue");
const ViewTraining = () => import("../views/ShowTraining.vue");
const ConfirmTraining = () => import("../views/ConfirmTraining.vue");
const ReadFile = () => import("../views/readFile.vue");
export default [
  {
    path: "ext-trainings/list",
    component: ListTrainings,
    meta: { requiresAuth: true },
    name: "trainings.list",
  },
  {
    path: "ext-trainings/add",
    component: NewTraining,
    meta: { requiresAuth: true },
    name: "training.add",
    props: true,
  },
  {
    path: "ext-trainings/edit/:id",
    component: EditTraining,
    meta: { requiresAuth: true },
    name: "training.edit",
    props: true,
  },
  {
    path: "ext-trainings/show/:id",
    component: ViewTraining,
    meta: { requiresAuth: true },
    name: "training.show",
    props: true,
  },
  {
    path: "ext-trainings/confirm",
    component: ConfirmTraining,
    meta: { requiresAuth: true },
    name: "training.confirm",
    props: true,
  },

  {
    path: 'ext-trainings/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true },
    name: 'Profile',
    props: true
  }
];

<template>
  <v-textarea
    class="whirelab-text-area black--text text-body-2"
    :placeholder="placeholder"
    :value="value"
    :required="required"
    :rules="rules"
    rounded
    :readonly="readonly"
    @keyup="updateValue($event.target.value)"
    :rows="rows"
    solo
    hide-details
    dense
    flat
    outlined
    auto-grow
  ></v-textarea>
</template>

<script>
export default {
  name: "WhirelabTextArea",
  props: ["placeholder", "value", "required", "rules", "readonly", "rows"],
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.whirelab-text-area {
  box-sizing: border-box;
  /* border: 1px solid #ccebe9; */
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  resize: none;
  color: rgba(0, 0, 0, 0.87);
}

.whirelab-input:focus {
  /* outline: none !important;
  border: 1px solid #CCEBE9;
  box-shadow: 0 0 3px #CCEBE9; */
}
</style>

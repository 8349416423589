<template>
  <v-navigation-drawer
    class="secondary"
    app
    light
    v-model="drawer"
    :permanent="!propDrawer"
    :expand-on-hover="$vuetify.breakpoint.mdAndDown ? false : !propDrawer"
    :clipped="$vuetify.breakpoint.mdAndDown"
  >
    <v-list-item
      class="py-1 secondary shadow"
      light
      style="height: 64px !important"
    >
      <v-list-item-icon>
        <v-img src="/KENMARE RESOURCES K_CMYK_LOGO.png" width="40"></v-img>
        <!-- <v-icon color="">mdi-kodi</v-icon> -->
      </v-list-item-icon>

      <v-list-item-title class="company-name">KENMARE</v-list-item-title>
    </v-list-item>

    <v-list dense nav>
      <template v-for="item in items">
        <v-list-item
          v-if="!item.childrens"
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary white--text active-whirelab-navegation"
        >
          <v-list-item-icon>
            <v-icon color="primary" v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="primary--text whirelab-navegation">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-for="item in items">
        <v-list-group
          v-if="item.childrens"
          :prepend-icon="item.icon"
          :key="item.title"
          no-action
          active-class="primary_text white--text "
          v-show="item.show"
        >
          <template v-slot:activator>
            <v-list-item-title
              class="white--text text-left text-subtitle-2 primary--text"
              >{{ item.title }}</v-list-item-title
            >
          </template>

          <template v-for="child in item.childrens">
            <v-list-group
              v-if="child.childrens"
              :key="child.title"
              no-action
              sub-group
              active-class="primary_text"
              v-show="child.show"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-2"
                    >{{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(c, i) in child.childrens"
                :key="i"
                :to="c.url"
                :exact="true"
                link
                active-class="primary_text"
                class="white--text text-subtitle-2"
                v-show="c.show"
              >
                <v-list-item-title v-text="c.title"> </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="child.url"
              :to="child.url"
              :exact="item.exact"
              router
              active-class="primary_text--text text-subtitle-2"
              class="px-3"
              style="width: 95%; margin-left: 5%"
              v-show="child.show"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="white--text" v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="text-left text-subtitle-2"
                  v-text="child.title"
                ></v-list-item-title>
              </v-list-item>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["propDrawer"],

  data: (props) => ({
    drawer: props.propDrawer,
    selectedItem: 0,
  }),

  watch: {
    propDrawer(val) {
      this.drawer = val;
    },
    drawer(val) {
      this.$emit("changeDrawer", val);
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    items() {
      return [
        {
          title: this.$t("trainings"),
          icon: "mdi-book-open-variant",
          url: "/employee/training/list",
          show: true,
          exact: true,
        },

        // {
        //   title: "Meetings",
        //   icon: "mdi-calendar-multiselect",
        //   route: "/entity/meetings/list",
        //   show: true,
        //   childrens: [
        //     {
        //       title: this.$t('list'),
        //       url: "/entity/meetings",
        //       icon: "mdi-format-list-bulleted-square",
        //     },
        //     {
        //       title: this.$t('add_new'),
        //       url: "/entity/meetings/add",
        //       icon: "mdi-plus",
        //     },
        //   ],
        // },
        // {
        //   title: this.$t("trainings"),
        //   icon: "mdi-book-open-variant",
        //   route: "/employee/trainings",
        //   show: true,
        //   childrens: [
        //     {
        //       title: this.$t("list"),
        //       url: "/employee/training/list",
        //       icon: "mdi-format-list-bulleted-square",
        //     },
        //     // {
        //     //   title: this.$t("add_new"),
        //     //   url: "/employee/training/add",
        //     //   icon: "mdi-plus",
        //     // },
        //   ],
        // },
      ];
    },
  },

};
</script>

<style scoped>
.workspace-btn {
  min-width: 5px !important;
  width: 95%;
  height: 95%;
}

.v-list__group__header__prepend-icon .v-icon {
  color: red !important;
}

/* primary white--text v-list-item--active v-list-item v-list-item--link theme--light
v-list-item v-list-item--link theme--light */

.active-whirelab-navegation .v-list-item__title,
.active-whirelab-navegation .v-icon {
  color: #fff !important;
}
</style>
<template>
  <div>
      <router-view />
  </div>
</template>

<script>
import AppLayout from "./../layouts/AppLayout";
export default {
  name: "EployeerRouters",
  created() {
    // this.$router.push('/employee/dashboard');
    this.$emit(`update:layout`, AppLayout);
  },
};
</script>

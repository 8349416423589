const ListPosition = () => import("../views/position.vue");

export default [
  {
    path: "positions/list",
    component: ListPosition,
    meta: { requiresAuth: true, permissions: "positions,list_positions"},
    name: "position",
  },
];

const dashboard = () => import('../views/EmployeeDashboard.vue')
export default [
  {
    path: '/employee/dashboard',
    component: dashboard,
    // meta: { requiresAuth: true },
    name: 'dashboard',
    props: true
  },
  // {
  //   path: '/employee',
  //   component: dashboard,
  //   // meta: { requiresAuth: true },
  //   name: 'dashboard',
  //   props: true
  // }
]
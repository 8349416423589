export default {
  methods: {
    accessLevel(currentUser, permission) {
      if (currentUser) {
        if (
          currentUser.role.name === "admin" ||
          currentUser.role.name === "administrator"
        ) {
          return true;
        }
        const index = currentUser.role.permissions.findIndex(
          (p) => p.name == permission
        );
        console.log({ currentUser }, permission, index);
        if (index != -1) {
          return true;
        } else {
          return false;
        }
        // return index > -1;
      }
    },
    hasAnyAccessLevel(currentUser, permissions) {
      if (currentUser) {
        if (
          currentUser.role.name === "admin" ||
          currentUser.role.name === "administrator"
        ) {
          return true;
        }
        const index = currentUser.role.permissions.findIndex(
          (p) => permissions.includes(p.name)
        );

        if (index != -1) {
          return true;
        } else {
          return false;
        }
        // return index > -1;
      }
    },
  },
};

const ListEmployees = () => import("../views/ListEmployees.vue");
// const ProfileEmployee = () => import("../views/ProfileEmployee.vue");
const ReadFile = () => import("../views/readFile.vue");

export default [
  {
    path: "employees/list",
    component: ListEmployees,
    meta: { requiresAuth: true, permissions: "employees,list_employees"},
    name: "employees",
  },
 
  // {
  //   path: "employees/show/:id",
  //   component: ProfileEmployee,
  //   meta: { requiresAuth: true },
  //   name: "employees.show",
  //   props: true,
  // },
  {
    path: 'employees/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true, permissions: "list_employees"},
    name: 'Profile',
    props: true
  }
];

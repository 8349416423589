const ListTrainings = () => import('../views/ListTrainings.vue')
const ShowTraining = () => import('../views/ShowTraining.vue')
const ConfirmTraining = () => import('../views/ConfirmTraining.vue')
const ReadFile = () => import("../views/readFile.vue");
const EditTraining = () => import("@/modules/entity/modules/training/views/ShowTraining.vue");
const AddTraining = () => import("@/modules/entity/modules/training/views/AddOrEditTraining.vue");
// src/modules/entity/modules/training/views/ShowTraining.vue
export default [
  {
    path: '/employee/training/list',
    component: ListTrainings,
    // meta: { requiresAuth: true },
    name: 'employee.trainings'
  },
  {
    path: '/employee/training/show/:id',
    component: ShowTraining,
    // meta: { requiresAuth: true },
    name: 'employee.training.show',
    props: true
  },
  {
    path: '/employee/training/edit/:id',
    component: EditTraining,
    // meta: { requiresAuth: true },
    name: 'employee.training.edit',
    props: true
  },
  {
    path: '/employee/training/add',
    component: AddTraining,
    // meta: { requiresAuth: true },
    name: 'employee.training.add',
    props: true
  },
  {
    path: '/employee/training/confirm-training',
    component: ConfirmTraining,
    // meta: { requiresAuth: true },
    name: 'employee.training.confirm',
    props: true
  },
  {
    path: '/employee/trainings/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true },
    name: 'employee.training.document.show',
    props: true
  }
]
<template>
  <div>
    <navigation-drawer @changeDrawer="changedDrawer" :propDrawer="drawer" />
    <app-bar @changeDrawer="changedDrawer" :propDrawer="drawer" />
    <v-main
      :style="`background-color: #f6fcfc; min-height: 100vh;`"
      :class="!drawer ? 'not-expanded' : 'expanded'"
    >
      <slot />
    </v-main>
  </div>
</template>

<script>
import NavigationDrawer from "@/components/NavigationDrawer";
import AppBar from "@/components/AppBar";
export default {
  components: { AppBar, NavigationDrawer },
  data: () => {
    let drawer = false;
    if (window.innerWidth < 1265)
      drawer = localStorage.getItem("drawer") == "true";

    return {
      drawer,
      firstOpen: true,
    };
  },
   watch: {
    "$vuetify.breakpoint.lgAndUp"(val) {
        this.drawer = val;
    },
  },
  methods: {
    changedDrawer(val) {

      if (!this.firstOpen) this.drawer = val;

      if (window.innerWidth > 1265 && !this.firstOpen) {
        localStorage.setItem("drawer", val);
      }else if(window.innerWidth < 1265 ){
        this.drawer = val;
      }
    },
  },
  created() {
    if (window.innerWidth > 1265) {
      this.drawer = localStorage.getItem("drawer") == "true";
    } else {
      this.drawer = false;
    }
    setTimeout(() => {
      if (window.innerWidth < 1265) this.drawer = false;
      this.firstOpen = false;
    }, 1000);
  },
};
</script>
<style >
.not-expanded {
  padding-left: 0px !important;
}

@media (min-width: 1265px) {
  .expanded {
    padding-left: 256px !important;
  }
}
</style>
const ListMeetings = () => import('../views/ListMeetings.vue')
const NewMeeting = () => import('../views/NewMeeting.vue')

export default [
  {
    path: '/employeer/meetings',
    component: ListMeetings,
    // meta: { requiresAuth: true },
    name: 'meetings'
  },
  {
    path: '/employeer/meeting/new-meeting',
    component: NewMeeting,
    // meta: { requiresAuth: true },
    name: 'meetings.profile',
    props: true
  }
]
const ListTrainings = () => import("../views/ListTrainings.vue");
const NewTraining = () => import("../views/AddOrEditTraining.vue");
const EditTraining = () => import("../views/AddOrEditTraining.vue");
const ViewTraining = () => import("../views/ShowTraining.vue");
const EmployeeShowTraining = () => import("@/modules/employee/modules/training/views/ShowTraining.vue");
const ConfirmTraining = () => import("../views/ConfirmTraining.vue");
const ReadFile = () => import("../views/readFile.vue");
export default [
  {
    path: "trainings/list",
    component: ListTrainings,
    meta: { requiresAuth: true },
    name: "trainings.list",
  },
  {
    path: "trainings/add",
    component: NewTraining,
    meta: { requiresAuth: true, permissions: "add_training" },
    name: "training.add",
    props: true,
  },
  {
    path: "trainings/edit/:id",
    component: EditTraining,
    meta: { requiresAuth: true, permissions: "update_training" },
    name: "training.edit",
    props: true,
  },
  {
    path: "trainings/show/:id",
    component: ViewTraining,
    meta: { requiresAuth: true, permissions: "add_training,update_training" },
    name: "training.show",
    props: true,
  },
  {
    path: "trainings/employee/show/:id",
    component: EmployeeShowTraining,
    meta: { requiresAuth: true },
    name: "training.employee.show",
    props: true,
  },
  {
    path: "trainings/confirm",
    component: ConfirmTraining,
    meta: { requiresAuth: true, permissions: "list_trainings" },
    name: "training.confirm",
    props: true,
  },

  {
    path: 'trainings/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true, permissions: "list_trainings" },
    name: 'Profile',
    props: true
  }
];

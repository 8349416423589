<template>
  <v-app-bar color="white" app flat elevation="1" :clipped-left="!propDrawer" :class="{'menu-colapse': !propDrawer}">
    <!-- <div class=""> -->
     <!-- <v-list-item class="py-1 secondary" light>
      <v-list-item-icon>
        <v-img src="/KENMARE RESOURCES K_CMYK_LOGO.png" width="40"></v-img>

      </v-list-item-icon>

      <v-list-item-title class="company-name">KENMARE</v-list-item-title>
    </v-list-item>
</div> -->
    <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>

    <v-spacer v-if="!toggle"></v-spacer>

    <v-text-field
      append-icon="mdi-magnify"
      :label="$t('search')"
      single-line
      hide-details
      dense
      v-model="search"
      outlined
      v-click-outside="onClickOutside"
      @focus="toggle = true"
      class="text-body-2"
      transition="slide-x-transition"
    ></v-text-field>

    <v-spacer v-if="!toggle"></v-spacer>

    

    <v-menu offset-y v-if="!toggle">
      <template v-slot:activator="{ on }">
        <v-btn text color="black" rounded v-on="on" icon  >
          <flag  :iso="currentFlag" v-bind:squared="false" />
          <!-- <v-icon right color="dark">mdi-menu-down</v-icon> -->
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(entry, index) in whirelabLanguages"
          :key="`${index}010`"
          @click="changeLocale(entry)"
        >
          <v-list-item-title class="text-left">
            <div class="d-flex justify-start align-center">
              <div class="mr-2">
                <flag :iso="entry.flag" v-bind:squared="false" />
              </div>
              <div class="mt-1">{{ entry.title }}</div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu left bottom v-if="!toggle">
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" color="dark" class="mr-2">
          <v-avatar size="22" item>
            <v-icon class="black--text">mdi-bell-outline</v-icon>
          </v-avatar>
        </v-btn>
      </template>
    </v-menu>

    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
      offset-y
      width="400px"
      v-if="!toggle"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" color="dark">
          <v-avatar size="22" item>
            <v-img
              :src="`${baseURL}/users/photo?picture=${currentUser?.picture}`"
              v-if="currentUser?.picture"
            ></v-img>
            <v-icon class="black--text" v-else>mdi-account-circle-outline</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card class="mx-auto text-left">
        <!-- <pre>
          {{currentUser?.employeeId}}
        </pre> -->
        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                :src="`${baseURL}/users/photo?picture=${currentUser?.picture}`"
                v-if="currentUser?.picture"
              ></v-img>
              <img :src="`/avatar.png`" v-else />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-body-1">
                {{
                  currentUser
                    ? currentUser?.employee?.firstName
                    : $t("not_defined")
                }}

                {{
                  currentUser
                    ? currentUser?.employee?.lastName
                    : $t("not_defined")
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-body-2">
                {{
                  currentUser ? currentUser?.employee?.email : $t("not_defined")
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item router to="/employee/profile">
            <v-list-item-action>
              <v-icon class="black--text">mdi-account-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-body-1">{{
              $t("profile")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon class="error--text">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-body-1 error--text">{{
              $t("logout")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { GC_USER_ID, GC_USER_TYPE, GC_AUTH_TOKEN } from "@/constants";
import GET_ME from "./../../auth/graphql/query/GetEmployee.gql";
import { mapActions, mapGetters } from "vuex";
import { baseURL } from "@/plugins/axios";
import UPDATE_USER_BY_ID from "@/modules/entity/modules/user/graphql/mutation/UpdateUserById.gql";
export default {
  name: "AppBar",
  apollo: {
    user: {
      query: GET_ME,
      fetchPolicy: "no-cache",
    },
  },
  props: ["propDrawer"],

  data: (props) => ({
    drawer: props.propDrawer,
    currentFlag: "pt",
    user: undefined,
    toggle: false,
    baseURL: baseURL,
    search: undefined,
  }),

  watch: {
    user: function (val) {
      if (val) {
        this.setUserState(val);
      } else {
        this.logout();
      }
    },
    search: function (val) {
      this.setSearch(val);
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    whirelabLanguages: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },

    settings: function () {
      return [
        {
          icon: "mdi-domain",
          title: this.$t("positions"),
          route: "/entity/positions/list",
          exact: true,
          show: true,
        },
        {
          icon: "mdi-key-variant",
          title: this.$t("access_level"),
          route: "/dashboard/entity/roles",
          exact: true,
          show: true,
        },
        {
          icon: "mdi-account-group",
          title: this.$t("user"),
          route: "/dashboard/entity/users",
          exact: true,
          show: true,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
      setSearch: "auth/setSearch",
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;
      localStorage.setItem("language", locale.language);
      localStorage.setItem("flag", locale.flag);
      this.updateLang(locale.language);
    },

    changeDrawer() {
      this.drawer = !this.propDrawer;
      this.$emit("changeDrawer", this.drawer);
    },

    logout() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_USER_TYPE);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
    },

    async updateLang(lang) {
      if (this.currentUser) {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_BY_ID,
          variables: {
            id: this.currentUser?.id,
            updateUserInput: {
              lang: lang,
              type: this.currentUser?.type,
            },
          },
        });
      }
    },
    onClickOutside() {
      this.toggle = false;
    },
  },
  created() {
    // console.log('login',this.user)
    if (localStorage.getItem(GC_USER_ID) === null) {
      this.logout();
    } else {
      if (localStorage.getItem("language") != null) {
        this.$i18n.locale = localStorage.getItem("language");
        this.currentFlag = localStorage.getItem("flag");
      } else {
        this.$i18n.locale = "pt";
        this.currentFlag = "pt";
      }
    }
    this.updateLang(this.$i18n.locale);
  },
};
</script>

<style scoped>

@media (min-width: 1265px) {
.menu-colapse{

  margin-left: 52px;
}
  
}
</style>
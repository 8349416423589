import Vue from "vue";
import VueRouter from "vue-router";

import authRoutes from "@/modules/auth/router";
import entityRoutes from "@/modules/entity/router";
import employeeRoutes from "@/modules/employee/router";
import userRoutes from "@/modules/entity/modules/user/router";

import { GC_AUTH_TOKEN, GC_AUTH_TOKEN_EXPIRES_IN } from "@/constants";
import { apolloClient } from "@/plugins/vue-apollo";
import GET_ME from "@/modules/auth/graphql/query/GetMe.gql";
import REFRESH_TOKEN from "@/modules/auth/graphql/mutation/RefreshToken.gql";
import GET_ME_WITH_PERMISSIONS from "@/modules/auth/graphql/query/GetMeWithPermitions.gql";
import store from '@/store';
// import { ca } from "date-fns/locale";

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...entityRoutes,
  ...employeeRoutes,
  ...userRoutes,
  { path: "", redirect: "/login" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const token = window.localStorage.getItem(GC_AUTH_TOKEN);
    const loginRoute = {
      path: "/login",
      query: { redirect: to.fullPath },
    };
    if (token) {
      try {

        if (to.meta.requiresAuth) {
          const currentUser = store.getters['auth/getCurrentUser'];
          let role = null;
          if (!currentUser) {
            const { data } = await apolloClient.query({
              query: GET_ME_WITH_PERMISSIONS,
              fetchPolicy: "network-only",
            });
            role = data.user.role;
            store.commit('auth/setUser', data.user);
          } else {
            await apolloClient.query({
              query: GET_ME,
              fetchPolicy: "network-only",
            });
            role = currentUser.role;
          }
          if (
            role.name === "admin" ||
            role.name === "administrator"
          ) {
            return next();
          } else
            if (!to.meta.permissions || role.permissions.find(
              (p) => (to.meta.permissions || '').split(',').includes(p.name)
            )) {
              return next();
            } else {

              alert("Sorry, you do not have access to this section. Please try again.");
              const canBack = router.currentRoute.path != '/' && router.currentRoute.path != '/login' && router.currentRoute.path != '/recover/account';
              return canBack ? next(false) : next('/entity/trainings/list');
            }

        } else {
          await apolloClient.query({
            query: GET_ME,
            fetchPolicy: "network-only",
          });
          return next();
        }
      } catch (error) {

        const noInternet = window.navigator.onLine ? false : true;
        if (noInternet) {
          alert("No Internet Connection");
          return next(false);
        } else {
          console.log("Auto Login Error: ", error);
          return next(loginRoute);
        }
      }
    }
    return next(loginRoute);
  }
  return next();
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {

    const tokenExpires = window.localStorage.getItem(GC_AUTH_TOKEN_EXPIRES_IN);

    if (tokenExpires) {
      const currentTime = new Date();
      const expireTime = new Date(tokenExpires);
      const differenceInMilliseconds = expireTime - currentTime;
      const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

      if (differenceInMinutes <= 30 && differenceInMinutes > 0) {
        try {
          const { data } = await apolloClient.mutate({
            mutation: REFRESH_TOKEN,
          });

          window.localStorage.setItem(GC_AUTH_TOKEN_EXPIRES_IN, data.refreshToken.expiredAt);
          window.localStorage.setItem(GC_AUTH_TOKEN, data.refreshToken.access_token);
          return next();
        } catch (error) {
          console.log("Auto Login Error: ", error);
          return next();
        }
      } else {
        return next();
      }
    } else {
      return next();
    }
  }
  return next();
});
export default router;

<template>
  <v-text-field
    :class="`whirelab-input black--text text-body-2 ${style_class}`"
    :value="value"
    :rules="rules"
    :append-icon="append_icon"
    :required="required"
    :readonly="readonly"
    :suffix="suffix"
    :type="type"
    rounded
    @keyup="updateValue($event.target.value)"
    :placeholder="placeholder"
    :prepend-inner-icon="prepend_inner_icon"
    :error-messages="error_messages"
    :error="error"
    outlined
    :disabled="disabled"
    dense
    solo
    flat
  ></v-text-field>
</template>

<script>
export default {
  name: "WhirelabInput",

  props: [
    "placeholder",
    "value",
    "required",
    "rules",
    "append_icon",
    "suffix",
    "type",
    "readonly",
    "prepend_inner_icon",
    "style_class",
    "error_messages",
    "error",
    "disabled",
  ],
  computed: {},
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.whirelab-input {
  width: 100%;
  height: 40px;
  padding: 4px 0px;
  box-sizing: border-box;
  border: 0px solid #000;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  resize: none;
  color: rgba(0, 0, 0, 0);
  padding-left: -1%;
}

.whirelab-input:focus {
  /* outline: none !important;
  border: 1px solid #ccebe9;
  box-shadow: 0 0 3px #ccebe9; */
}
</style>

const ProfileEmployee = () => import('../views/ProfileEmployee.vue')
const ReadFile = () => import('../views/readFile.vue')
export default [
  {
    path: '/employee/profile',
    component: ProfileEmployee,
    meta: { requiresAuth: true },
    name: 'Profile',
    props: true
  },
  {
    path: '/employee/profile/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true },
    name: 'Profile',
    props: true
  }
]

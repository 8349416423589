import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
//import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getMainDefinition } from "apollo-utilities";
import { WebSocketLink } from "apollo-link-ws";
import { GC_AUTH_TOKEN } from "./../constants";

const httpEndpoint = 
 process.env.VUE_APP_GRAPHQL_HTTP ||  
"https://kenmare-api-training.whirelab.com/graphql";

const wsEndpoint = 
// process.env.VUE_APP_GRAPHQL_WS ||
  "wss://kenmare-api-training.whirelab.com/graphql";

// HTTP connection to the API
// const httpLink = createHttpLink({
//   // You should use an absolute URL here
//   uri: "http://localhost:3001/graphql",
//   // uri: "https://rhapi2.whirelab.com/graphql",
// });

const httpLink = createUploadLink({
  uri: httpEndpoint,
});

const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
    // reconnect: false,
  },
});

// Cache implementation
const cache = new InMemoryCache({ addTypename: false });

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(GC_AUTH_TOKEN);
  const locale = localStorage.getItem("lang");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      "Accept-Language": `${locale}`,
    },
  });
  return forward(operation);
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(link),
  cache,
  connectToDevTools: true,
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(VueApollo);
